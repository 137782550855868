import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import AboutComp from "../component/HomeLayout/homeOne/About";
import SliderDorf from "../component/slider/SliderDorf";

class About extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="active-dark"> 
                <PageHelmet pageTitle='About' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                    {/* Start About Area */}
              
                        <div className="row">
                            <div className="col-lg-12">
                                <SliderDorf />
                                </div>
                    </div>

                    {/* End About Area */}
                {/* End Breadcrump Area */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5">
                    <AboutComp />
                </div>
                {/* End About Area */}

                    {/* Start CounterUp Area */}
                    <div className="rn-counterup-area ptb--120 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <span className="subtitle">Removing Humans From The Equation</span>
                                        <h2 className="title">Our Company</h2>
                                        <p className="description">We strive to automate any and all of your processes</p>
                                        <img src="/assets/images/Header_Image_1.png" alt="Inveve Creative Agency" />;
                                </div>
                                </div>
                            </div>
                            <CounterOne />
                        </div>
                    </div>
                    {/* End CounterUp Area */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--34" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="inner">
                                    <div className="content-wrapper">
                                        <div className="content">
                                            <h4 className="theme-gradient">Learn More</h4>
                                                <p>If anything has peaked your interest, if you would just like to connect with us to learn more, or if you would like to consult with us on a project, click the button below to email us now.</p>
                                                <a className="btn-default" href="mailto:info@inveve.com">Get Started</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--25">
                                    <span className="subtitle">Our team</span>
                                    <h2 className="title">The Inveve Team</h2>
                                    <p className="description">Our company is made up of 4 main teams.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/blog-01.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                            <h4 className="title">Developers</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/team/digital-marketing.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                            <h4 className="title">Marketers</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/team/team_bg.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Data Scientests</h4>
                                        <p className="designation">Sr. Web Developer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                    </ul>
                                </div>
                            </div>
                                {/* End Single Team  */}
                                {/* Start Single Team  */}
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                    <div className="team">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/team/team_bg2.jpg" alt="Blog Images" />
                                        </div>
                                        <div className="content">
                                            <h4 className="title">Managers</h4>
                                            <p className="designation">Sr. Web Developer</p>
                                        </div>
                                        <ul className="social-icon" >
                                            <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>
                                            <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                            <li><a href="https://twitter.com/"><FaTwitter /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--5 ptb--120">
                    <div className="container">
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Top clients</span>
                                    <h2 className="title">We worked with brands.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
               </div>
            </React.Fragment>
        )
    }
}
export default About