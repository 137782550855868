import React, { Component } from "react";
import Lottieinveve4 from "../../../elements/service/Lottieinveve4";

import { Link } from 'react-router-dom';
class About extends Component{
    render(){
        let title = 'What is Inveve?',
        description = 'Headquarted in New York, NY, yet based worldwide, Inveve is a full-service consulting company focused on bringing companies of any age and scale into the data driven world.',
        description2 = 'We do this by first auditing your company’s tech stack (or lack of). Allowing us to architect and deploy technology that works to better automate your company or business.';

        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <Lottieinveve4 className="w-100" />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description">{description2}</p>
                                        <div className="purchase-btn">
                                            <Link className="btn-transparent" to="/about">Learn More</Link>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;