import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import TextLoop from "react-text-loop";

class SliderDorf extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 pt--50 d-flex align-items-center bg_image bg_image--1" data-black-overlay="8">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient"> {" "}
                               <TextLoop>
                                    <span className="title theme-gradient">Create.</span>
                                    <span className="title theme-gradient">Design.</span>
                                        <span className="title theme-gradient">Develop.</span>
                                        <span className="title theme-gradient">Analytics.</span>
                                        <span className="title theme-gradient">Insights.</span>
                                        <span className="title theme-gradient">Marketing.</span>
                                    </TextLoop>{" "}</h1> 
                            </div>
                        </div>
                    </div>
                    
                

                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}

export default SliderDorf;