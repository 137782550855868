import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Particles from 'react-particles-js';
import { Row, Col, Container } from "react-bootstrap";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Creative One Page',
        description: 'There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]
class MainDemo extends Component{
    render(){
        const PostList = BlogContent.slice(0 , 3);
        return(
            <div className="active-dark"> 
                <Helmet pageTitle="Home" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="Inveve-Logo-03.png" />

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency with-particles" id="home">
                    <div className="frame-layout__particles">
                        <Particles
                            params={
                                {
                                    "particles": {
                                      "number": {
                                        "value": 202,
                                        "density": {
                                          "enable": true,
                                          "value_area": 481.0236182596568
                                        }
                                      },
                                      "color": {
                                        "value": "#ffffff"
                                      },
                                      "shape": {
                                        "type": "circle",
                                        "stroke": {
                                          "width": 0,
                                          "color": "#000000"
                                        },
                                        "polygon": {
                                          "nb_sides": 5
                                        },
                                        "image": {
                                          "src": "img/github.svg",
                                          "width": 100,
                                          "height": 100
                                        }
                                      },
                                      "opacity": {
                                        "value": 1,
                                        "random": true,
                                        "anim": {
                                          "enable": true,
                                          "speed": 1,
                                          "opacity_min": 0,
                                          "sync": false
                                        }
                                      },
                                      "size": {
                                        "value": 3,
                                        "random": true,
                                        "anim": {
                                          "enable": false,
                                          "speed": 4,
                                          "size_min": 0.3,
                                          "sync": false
                                        }
                                      },
                                      "line_linked": {
                                        "enable": false,
                                        "distance": 192.40944730386272,
                                        "color": "#ffffff",
                                        "opacity": 0.4,
                                        "width": 1
                                      },
                                      "move": {
                                        "enable": true,
                                        "speed": 1,
                                        "direction": "none",
                                        "random": true,
                                        "straight": false,
                                        "out_mode": "out",
                                        "bounce": false,
                                        "attract": {
                                          "enable": false,
                                          "rotateX": 600,
                                          "rotateY": 600
                                        }
                                      }
                                    },
                                    "interactivity": {
                                      "detect_on": "canvas",
                                      "events": {
                                        "onhover": {
                                          "enable": true,
                                          "mode": "repulse"
                                        },
                                        "onclick": {
                                          "enable": false,
                                          "mode": "push"
                                        },
                                        "resize": true
                                      },
                                      "modes": {
                                        "grab": {
                                          "distance": 400,
                                          "line_linked": {
                                            "opacity": 1
                                          }
                                        },
                                        "bubble": {
                                          "distance": 250,
                                          "size": 0,
                                          "duration": 2,
                                          "opacity": 0,
                                          "speed": 3
                                        },
                                        "repulse": {
                                          "distance": 73.08694910712106,
                                          "duration": 0.4
                                        },
                                        "push": {
                                          "particles_nb": 4
                                        },
                                        "remove": {
                                          "particles_nb": 2
                                        }
                                      }
                                    },
                                    "retina_detect": true
                                  }
                            }
                        />
                    </div>
                    <div className="slider-wrapper">
                    <SliderOne />
                
                </div>
                {/* End Slider Area   */}

                

                {/* Start About Area */}
                <div className="about-area ptb--120">
                    <About />
                </div>
                
                {/* End About Area */}

                 {/* Start CounterUp Area */}
                 <div className="rn-counterup-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Removing Humans From The Equation</span>
                                    <h2 className="title">Our Company</h2>
                                    <p className="description">We strive to automate any and all of your processes</p>
                                    <img src="/assets/images/Header_Image_1.png" alt="Inveve Creative Agency" />;
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120">
                    <div className="portfolio-sacousel-inner mb--55">
                        <Portfolio />
                    </div>
                </div>
                {/* End Portfolio Area */}
                  {/* Start Service Area  */}
                  <div className="service-area ptb--120 bg_color--5">
                   <div className="container">
                        <ServiceTwo />
                   </div>
                </div>
                {/* End Service Area  */}
                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--120">
                    <div className="container">
                    <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Our Inspiration</span>
                                    <h2 className="title"></h2>
                                </div>
                            </div>
                        </div>
                        <Testimonial />
                    </div>
                </div>
                {/* End Testimonial Area */}

                
                {/* Start Brand Area */}
                
                <div className="rn-brand-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center mb--30">
                                    <span className="subtitle">Top clients</span>
                                    <h2 className="title">Companies we have worked with.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                </div>
                <Footer />
                
            </div>
        )
    }
}
export default MainDemo;