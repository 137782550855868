import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/CW-NYC-logo-wht.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/TGS-New Logo-Vector.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/happycanyonfarms-logo-WHITE.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/petspalz-logo-final-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/Bask-Logo-No-Backround copy.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/Connected-Chain-Logo-No-Backround copy.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/Lash-Final copy.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/ethereum-logo-white copy.png" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;