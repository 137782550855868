import PropTypes from "prop-types";
import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle} | Inveve </title>
                    <meta name="robots" content="index, follow" />
                    <meta name="title" content="Inveve - Data Driven Consulting" />
                    <meta name="description" content="A full-service consulting company focused on bringing companies of any age and scale into the data driven world. " />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://inveve.com" />
                    <meta property="og:title" content="Inveve - Data Driven Consulting" />
                    <meta property="og:description" content="A full-service consulting company focused on bringing companies of any age and scale into the data driven world. " />
                    <meta property="og:image" content="\assets\images\inveve_social_share_image.jpg" />
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content="https://inveve.com" />
                    <meta property="twitter:title" content="Inveve - Data Driven Consulting" />
                    <meta property="twitter:description" content="A full-service consulting company focused on bringing companies of any age and scale into the data driven world. " />
                    <meta property="twitter:image" content="\assets\images\inveve_social_share_image.jpg" />
                </Helmet>
            </React.Fragment>
        )
    }
}
PageHelmet.propTypes = {
    title: PropTypes.string
};
export default PageHelmet;
