import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

import inveve_arcitect from './Animations/inveve_arcitect.json';

const Lottieinveve2 = () => {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: inveve_arcitect,
    });
    // More logic goes here
  }, []);
  return <div style={{ height: 150, width: 300, paddingLeft: 60 }} ref={anime}></div>;
};

export default Lottieinveve2;