import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>“I think generally ... all input is error.”</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Elon Musk </span> - CEO, TESLA & SPACEX</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>“If you can’t explain it simply, you don’t understand it well enough.”</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Albert Einstein </span> - Theoretical Physicist</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"While data and analytics present us with many opportunities to take more calculated risks, increase efficiency and guide decisions - don’t forget about human instinct. Trust your intuition, stay curious and always put your people first if you want to thrive in the long-term."</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Sir Richard Branson </span> - CEO, Virgin Group</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"My goal is to simplify complexity."</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Jack Dorsey </span> - CEO, SQUARE & TWITTER</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"Whereas most technologies tend to automate workers on the periphery doing menial tasks, blockchains automate away the center. Instead of putting the taxi driver out of a job, blockchain puts Uber out of a job and lets the taxi drivers work with the customer directly."</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Vitalik Buterin </span> - Founder, Ethereum</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>“The root problem with conventional currency is all the trust that’s required to make it work. The central bank must be trusted not to debase the currency, but the history of fiat currencies is full of breaches of that trust.”</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Satoshi Nakamoto </span> - Founder, Bitcoin</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>"It's impracticable to halt the exponential advancement of technology."</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Dr. Ben Goertzel</span> - CEO, SingularityNET</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>JON CUMMINS </span> - COO, AMERIMAR ENTERPRISES, INC.</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Elon-Musk-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Albert-Eninstien-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/richard-branson-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Jack-Dorsey-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/Vitalik-Buterin-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/bitcoin-satoshi-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/dr ben goertzel-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/client/charles-hoskinson-Inveve copy.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;