import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp, FiMail, FiCopy } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Business Stratagy',
        description: "From the formulation of goals to the implementation initiatives. We step in to tune and develop your company's strategy."
    },
    {
        icon: <FiLayers />,
        title: 'Fullstack Development',
        description: 'We build web applications. From the client side to the server side, we design fast and beautiful applications.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing & Reporting',
        description: 'Once your brand is built, your application developed, and your product optimized, we build out a digital marketing strategy that will work for you.'
    },
    {
        icon: <FiMonitor />,
        title: 'Brand Development',
        description: 'Building better brand experiences means modernizing how you manage content. We will do everything from logo design to supply chain enhancement.'
    },
    {
        icon: <FiMail />,
        title: 'Data Collection',
        description: 'From rebuilding the backend to web scraping, we optimize your data pipeline to collect clean, accurate data that will be usefull in optimizing and automating your business.'
    },
    {
        icon: <FiCopy />,
        title: 'Blockchain Intergration',
        description: "We will move your company's infrastructure to a immutable distributed ledger, enhancing your pipeline. No matter your industry, utilizing a blockchain will enhance your business."
    },
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services We Provide',
        description = 'We step in anywhere you would like. <br> We can help you no matter where you are in your business lifecycle. <br> From conceptualization to designing to analysing to building, we do it all.',
        subtitle= 'What we can do for you';
        return(
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <span className="subtitle">{subtitle}</span>
                                <h2 className="title">{title}</h2>
                                <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-12 mt--30">
                            <div className="row service-main-wrapper">
                                {ServiceList.map( (val , i) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <a href="/service-details">
                                            <div className="service service__style--2 text-left">
                                                <div className="icon">
                                                    {val.icon}
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">{val.title}</h3>
                                                    <p>{val.description}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
