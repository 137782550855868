import React, { Component, useState } from "react";
import { FiActivity, FiCast, FiMap, FiLayers } from "react-icons/fi";
import Lottieinveve from "./Lottieinveve";
import Lottieinveve2 from "./Lottieinveve2";
import Lottieinveve3 from "./Lottieinveve3";

const ServiceList = [
    {
        icon: <Lottieinveve2 />,
        title: 'Architect',
        description: 'We design and architect the technology you want to build. Leveraging our experience, data and technologies to bring our customers the results they desire in an efficient manner.'
    },
    {
        icon: <Lottieinveve />,
        title: 'Develop',
        description: 'We will create, audit and/or overhaul your entire infastructure, ensuring your business is equiped from the ground up with the latest technologies to fit your needs.'
    },
    {
        icon: <Lottieinveve3 />,
        title: 'Connect',
        description: 'Digitize your business with us. Using our in-house algorithms, let us connect your brand with the millions of customers that have yet to be captured.'
    },
]
class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row row--25">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;