const BlogContent = [
    {
        images: '01',
        title: 'How does your website and branding impact sales?',
        category: 'Web Development'
    },
    {
        images: '02',
        title: 'Why should you implement Blockchain within your company.',
        category: 'Blockchain'
    },
    {
        images: '03',
        title: 'Using Data Science to make business decisions.',
        category: 'Data Science'
    },
    {
        images: '04',
        title: ' Getting tickets to the big show',
        category: 'Development'
    },
    {
        images: '05',
        title: 'A big ticket gone to be an interesting ',
        category: 'Management'
    },
    {
        images: '06',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
    {
        images: '07',
        title: 'The Home of the Future Could Bebes',
        category: 'Design'
    },
]

export default BlogContent;