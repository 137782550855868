import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";

import inveve_about from './Animations/inveve_about.json';

const Lottieinveve4 = () => {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: inveve_about,
    });
    // More logic goes here
  }, []);
  return <div style={{ height: 500, width: 450, paddingRight: 70 }} ref={anime}></div>;
};

export default Lottieinveve4;